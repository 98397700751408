.datasets-container{

}

.datasets-content a{
    color:#000 !important;
    padding-left: 10px;
}

.datasets-content a:hover{
    color:#007bff !important;
    text-decoration: underline !important;
}

.datasets-left a:hover{
    font-color:#000 !important;
}


.datasets-content h3{
    font-size: 32px;
    font-weight: 600;
    color: #474747;
}

@media (max-width: 575.98px) {
    .datasets-container{
	padding-left: 20px !important;
	padding-right: 20px !important;
    }

    .datasets-content a{
	font-size: 20px;
	padding-left: 10px;
    }

}
