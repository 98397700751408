.head-container{
    margin-top:0px;
    width:100%;
    height:auto;
    top:0px;
    position:fixed;
    background:#fff;
    background-size:100% 100%;
    z-index:9999;
}

.head-contain-inner{
    width:1200px;
    height:80px;
    margin:0 auto;
}

.head-contain-inner img{
    width:95px;
    margin-top:19px;
    margin-bottom:19px;
}

.head-nav ul li{
    margin:33px 0 0 86px;
    float: left;
    line-height:16px;
    list-style: none;
}


.head-nav ul li a{
    color:#000;
    font-size:20px;
    font-weight:700;
}

.head-nav ul li a:hover{
    color:#007bff;
    text-decoration: underline;
}

.head-menu {
    padding: 0px 20px;

}

.head-menu ul li{
    position: relative;
    padding: 15px 20px;
    /* border-top: 0.3px solid #474747; */
    text-align: center;
}

.menu-bottom-line{
    border-bottom: 0.3px solid #474747;
}

.head-menu ul li a{
    color:#000;
    font-size:14px;
    font-weight:700;
}

.menu-button{
    float: right;
    padding: 22px 22px;
}
