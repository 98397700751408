/*footer*/
.footer{
    width:100%;
    background:#2a2a2a;
    height:auto;
    color:#949695;
    font-size:14px;
    clear: both;

}
.footer-cont{
	height:auto;
	overflow: hidden;
	margin:0 auto;
	padding:80px 20px;
}
.cont-fl{
	width:360px;
	line-height:24px;
}
.cont-fl .flogo img{
        margin-right:10px;
	margin-bottom:30px;
}
.cont-fl .link img{
	width:14px;
	height:14px;
	margin-right:15px;
	margin-top:40px;
}
.cont-fl .link img.f5s{
	width:20px;
	height:14px;
}
.cont-fm{
	line-height:24px;
	margin:0 30px;
}
.cont-fm p{
	margin-top:20px;
}
.cont-fm p.f16,.cont-fr p.f16{
	color:#fff;
	font-size:20px;
	font-weight:700;
	margin-top:20px;
}
.cont-fm p img{
	margin-right:15px;
	position:relative;
}
.cont-fm p.f7s,.cont-fr p.f7s{
	margin-top:30px;
} 
.cont-fm p.f7s img{
	width:15px;
	height:15px;
}
.cont-fm p.f8s img{
	width:15px;
	height:17px;
}
.cont-fm p.f9s img{
	width:17px;
	height:15px;
}
.cont-fr{
	width:280px;
	margin-left:40px;
	line-height:24px;
}
.cont-fr img{
	width:15px;
	height:10px;
	margin-right:15px;
}
.cont-fr p.ftime{
	margin-top:10px;
}


@media (max-width: 575.98px) {

    .cont-fm{
	line-height:24px;
	margin:0 0px;
    }


}
