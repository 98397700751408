.banner-cont{
    position:relative;
    width:100%;
    height:250px;
    background:url(./images/res-bg.jpg) repeat-y;
    background-size:100% 100%;
    margin-bottom:63px;
}

.banner-text{
    width:1200px;
    margin:0 auto;
    color:#fff;
    padding-top:80px;
}

.f30{
    padding-top: 40px;
    font-size:52px;
    font-weight:700;
}

.f16{
    font-size:16px;
    margin-top:5px;
}

@media (max-width: 575.98px) {

    .banner-cont{
	height:220px;
	margin-bottom:43px;
    }
    
    .banner-text{
	padding-left: 20px;
    }

    .f30{
	font-size:36px;
    }

}
