html, body{
overflow-x: hidden;
}

html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
body,
p,
div,
h1,
h2,
h3,
ul,
li {
  padding: 0;
  margin: 0;
}
li,
ul {
  list-style: none;
}

a{
    color:#000;
}

a:hover{
    color:#007bff;
}


body {
  color: #474747;
  background:#ffffff;
}
.fl{
  float:left;
}
.fr{
  float:right;
}

.clearfix {
  clear: both;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}


.h1-title{
    font-size:50px;
    line-height: 1.2;
    color:#fff;
}

.h2-title{
    padding-top:80px;
    font-size:70px;
    line-height: 1.2;
}

.h2-title-black{
    font-size:70px;
    line-height: 1.2;
    margin-bottom: 50px;
}

.h3-content{
    font-size:20px;
    line-height: 1.7;
}

.home-container{
    margin-top:0px;
    width:100%;
    height:auto;
    background:url(./images/ban.png) no-repeat;
    background-size:100% 100%;
}

.home-contain-inner{
    margin:0 auto;
}

.logo{
    width:95px;
    margin-top:19px;
    margin-bottom:19px;
}

.home-top{
    width:100%;
    height:auto;
    position: relative;
    z-index:9999;
}

.home-top-fixed{
    top:0px;
    left:0px;
    position:fixed;
    width:100%;
    height:auto;
    background-color: rgba(0,0,0,0.9);
    background-size:100% 100%;
    z-index:9999;
}

.home-top-inner{
    height: 80px;
    margin: 0 auto;
}

.home-top-fixed ul li a{
    color:#000;
}

.home-top-fixed ul li a:hover{
    color:#007bff;
    text-decoration: underline;
}


.home-nav ul li{
    margin:33px 0 0 86px;
    float: left;
    line-height:16px;
    list-style: none;
}


.home-nav ul li a{
    color:#fff;
    font-size:20px;
    font-weight:700;
    color:#fff;
}

.home-nav ul li a:hover{
    color:#007bff;
    text-decoration: underline;
}

.unimg{
    width:100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top:15px;
}

.unis{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.unis li{
    display:inline-block;
    margin:5px 0 0 0px;
    padding: 0px 25px 0 25px;
    float:left;
    line-height:1.2em;
    font-size:26px;
}

.unis li a{
    color: #495057;
    font-size: 20px;
    font-weight: 500;
    line-height:1em;
}

.unis li a:hover{
    color:#007bff;
    text-decoration: underline;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.b-div{
    position: relative;
    width:100%;
    height:100%;
    z-index: 0;
}

.b-text{
    font-size:63pt;
    color:#fff;
}

.b-learn-more a{
    font-size:24px;
    color:#fff;
    font-weight:700;
}

.b-learn-more a:hover{
    color:#0072b9;
    text-decoration: underline;
}

.f16{
    font-size:16px;
}

.f16 a{
    color:#fff;
}

.f16 a:hover{
    color:#0072b9;
    text-decoration: underline;
}

.news{
    margin:0 auto;
    height:auto;
}

.news .title{
    font-size:60px;
    padding-bottom: 30px;
}

.news .l-img img{
    width:580px;
    height:440px;
}

.news .r-text{
    border-top:1px solid #797979;
    border-bottom:1px solid #797979;
    height:440px;
    margin-left: 25px;
}

.news .r-inner-text{
    position: relative;
    font-size:24px;
    top:15%;
    width:80%;
    height:80%;
    margin: auto;
}

.news .r-text a{
    color:#212529;
    line-height: 1.5em;
    font-size: 24px;
    font-weight: 700;
}

.news .r-text a:hover{
    color:#0072b9;
    text-decoration: underline;
}

.research{
    width:100%;
    height:auto;
    background:url(./images/ban2.png) no-repeat;
    background-size:100% 100%;
    color:#fff;
}

.cont-l{
    line-height:28px;
    color:#fff;
    padding-top:30px;
    padding-bottom:30px;
}


.p-top{
    text-align: right;
    padding-bottom:40px;
    margin-block-start:0em;
    margin-block-end:0em;
    border-bottom: 1px solid #fff;
}

.research a{
    font-size:30px;
    line-height:1em;
    color:#fff;
    font-weight: 700;
}

.research a:hover{
    color:#0072b9;
    text-decoration: underline;
}

.p-top a{
    font-size:37px;
    line-height:1em;
    color:#fff;
}

.right-text{
    width:400px;
}


.turn-blue{
    padding-top:40px;
    padding-bottom:40px;
}

.turn-blue:hover a{
    color:#0072b9;
    text-decoration: underline;
}

.bottom-line{
    border-bottom: 1px solid #fff;
}

.right-line{
    border-right: 2px solid #F1F1F1;
}

.research-cooperate{
    width:100%;
}

.industry{
    background-color: #F7F7F7;
    background-size:100% 100%;
}

.industry-cooperate{
    width:100%;
    margin:0 auto;
}


.first-pic span,.second-pic span{
	position:relative;
	left:10px;
	top:-70px;
	bottom:0px;
}

.first-pic, .second-pic{
    width: 100%;
}

.first-pic img{
    width: 100%;
    height: 100px;
}

.second-pic img{
    width: 100%;
    height: 100px;
    padding-left:30px;
}

.mar-t30{
	margin-top:-30px;
	position:relative;
	top:-20px;
}

.oppo{
	background:#f2f2f2;
	width:100%;
	height:200px;
}

.oppo-cont{
	width:1200px;
	margin:0 auto;
}

/* for menu */
.home-menu {
    padding: 0px 20px;
}

.home-menu ul li{
    position: relative;
    padding: 15px 20px;
    text-align: center;
}

.home-menu ul li a{
    color:#fff;
    font-size:14px;
    font-weight:700;
}

.menu-button{
    float: right;
    padding: 22px 22px;
}


@media (max-width: 575.98px) {

    .h1-title{
	font-size:34px;
	line-height: 1.2;
	color:#fff;
	font-weight: 500;
    }

    .h2-title-black{
	font-size:34px;
	line-height: 1.2;
	margin-bottom: 30px;
	padding-left: 20px;
    }

    .h2-title{
	font-size:34px;
	line-height: 1.2;
	margin-bottom: 30px;
	padding-left: 20px;
    }

    .h3-content{
	font-size:20px;
	line-height: 1.5;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 30px;
    }

    .slogan{
	padding-left: 20px;
	padding-right: 20px;

    }

    .news .title{
	font-size:34px;
	padding-left: 20px;
	padding-bottom: 0px;
    }

    .second-pic img{
	width: 100%;
	height: 320px;
	padding-left: 20px;
	padding-right: 20px;
    }

    .right-text{
	padding-left: 20px;
	padding-right: 20px;
	width:100%;
    }

    .news .r-text a{
	color:#212529;
	line-height: 1.5em;
	font-size: 22px;
	font-weight: 700;
    }

    .research a{
	font-size:24px;
	line-height:1.5em;
	color:#fff;
	font-weight: 700;
    }

    .turn-blue{
	padding-top:20px;
	padding-bottom:20px;
    }

    .unimg{
	padding-left: 20px;
	padding-right: 20px;
	height: 200px;
    }


    .unis li{
	display:inline-block;
	margin:5px 0 0 0px;
	padding: 0px 15px 0 15px;
	float:left;
	line-height:1.2em;
	font-size:26px;
    }

    .unis li a{
	color: #495057;
	font-size: 14px;
	font-weight: 500;
	line-height:1em;

    }

    .logo{
	width:95px;
	margin-top:19px;
	margin-bottom:19px;
	padding-left: 20px;
    }

    .home-container{
	background:url(./images/ban_m.png) no-repeat;
	background-size:100% 100%;
    }

    .research{
	background:url(./images/ban2_m.png) no-repeat;
	background-size:100% 100%;
    }

    /* .home-top-inner{ */
    /* 	height:80px; */
    /* 	margin:0 auto; */
    /* 	background-color: #ffff; */
    /* } */

}
