
.news_content{
    padding-bottom: 50px;
}

.news_content_year{
    padding-bottom: 10px;
}

.news_content_year h3{
    font-size: 32px;
    font-weight: 600;
    color: #474747;
}

.news_item{
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
}

.new_icon{
    height: 20px;
}

.news_content a {
    color: #000;
    font-weight: 600;
}

.news_content a:hover{
    color:#007bff;
    text-decoration: underline;
}

@media (max-width: 575.98px) {
    .news_content_year h3{
	padding-left: 20px !important;
	padding-right: 20px !important;
    }

}


