.research-content a{
    color:#000 !important;
}

.research-content a:hover{
    color:#007bff !important;
    text-decoration: underline !important;
}

.research-content h3{
    font-size: 32px;
    font-weight: 600;
    color: #474747;

}

.Paper {
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

@media (max-width: 575.98px) {
    .research-container{
	padding-left: 20px !important;
	padding-right: 20px !important;
    }

}

