.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
	animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
	transform: rotate(0deg);
    }
    to {
	transform: rotate(360deg);
    }
}

.about-text{
    line-height: 1.5;
}

.about-text h5{
}

.email{
    color:#007bff;
}

.about-text ul {
    padding-left: 32px;
}

.about-text li {
    list-style-type: disc;
}

.about-text li:before {
    /* content: "•"; /\* Insert content that looks like bullets *\/ */
    padding-right: 8px;
    color: blue; /* Or a color you prefer */
}


.about-content{
    width:900px;
    margin: 0 auto;
}

.about-image{
    padding-left: 30px;
}


.about-text a{
    color: #000;
    font-weight: 600;
}

.btn-outline-primary{
    color: #474747 !important;
    box-shadow: rgb(0 0 0 / 0.35) 0px 0px 3px 3px !important;
    border-color: rgb(0, 0, 0, 0)  !important;
    font-weight: 500 !important;
}

.btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open>.dropdown-toggle.btn-primary{
    color: #474747 !important;
    box-shadow:  rgb(0 0 0 / 0.35) 0px 0px 3px 3px !important;
    border-color: rgb(0, 0, 0, 0) !important;
}

.btn-outline-primary:hover{
    color: #fff !important;
    box-shadow: rgb(0 123 255 / 0.35) 0px 0px 3px 3px !important;
    border-color: rgb(0, 0, 0, 0) !important;
}


.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{
    color: #fff !important;
    box-shadow: rgb(0 123 255 / 0.35) 0px 0px 3px 3px !important;
    border-color: rgb(0, 0, 0, 0) !important;
}


.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}


.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-right: 0px !important;
    padding-left: 0px !important;
}


.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0px !important;
    padding-left: 0px !important;
}


@media (max-width: 1025px) {
    .m-hidden{
	display: none;
    }
}

@media (min-width: 1025px) {
    .d-hidden{
	display: none;
    }
}

@media (max-width: 575.98px) {
    

    .about-content{
	width:100%;
	margin: 0 auto;
	padding-left: 20px !important;
	padding-right: 20px !important;
    }
    
    .about-text{
	line-height: 1.5;
	
    }

    .about-image{
	width: 100%;
	padding-left: 0px;
	padding-bottom: 50px;
    }
}
