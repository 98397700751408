

.Person-container {
    width: 100px;
    background: red;
    margin: 0 auto;
    @include make-container();
}

.p-box{
    padding-bottom: 20px;
}

.Person-float {
  float: lef
}

.mem_name a{
    color: #000;
}

.mem_name a:hover{
    color:#007bff;
    text-decoration: underline;
}

@media (max-width: 575.98px) {
    .team-container{
	padding-left: 20px !important;
	padding-right: 20px !important;

    }

}
